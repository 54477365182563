.quiz-theme {
    margin-top: 40px;
    margin-right: 10px;
    flex-basis: 24.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    background-color: rgba(255, 255, 0, 0.297);
    cursor: pointer;
    position: relative;
}

.quiz-theme > img {
    margin-bottom: 20px;
    width: 128px;
    position: relative;
    z-index: 2;
}

.quiz-theme p {
    text-align: center;
    padding: 8px 0;
    margin: 8px 0;
    font-size: 26px;
    background-color: #6b400c;
    color: white;
    width: 100%;
    position: relative;
    z-index: 2;
}

img.lock-image{
    width: 210px;
    height: 210px;
    opacity: 1;
    position: absolute;
    z-index: 1;
}