.admin-panel-home-page  {
    text-align: left;
}

span.bold {
    font-weight: bold;
}

span.space {
    margin-right: 5px;
}