.register-page input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
}

input.error {
    border: 1px solid red;
}