.admin-panel-questions-create-question-heading {
    text-align: center;
}

form .input-inline {
    padding-left: 8%;
    width: 92%;
    display: inline-block;
}

.remove-input {
    position: relative;
    top: -33.5px;
}

.admin-panel-question-flex {
    display: flex;
    align-content: center;
    margin: 20px 0;
}

.admin-panel-question-flex > * {
    margin:0 8px 0 0;
}