.wrong-answer {
    border: 1px solid black;
    background-color: rgba(219, 69, 69, .8);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrong-answer-heading {
    color: white;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.wrong-answer-text {
    color: white;
}

.wrong-answer-img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.wrong-answer-report-error {
    font-weight: bold;
    text-decoration: underline;
}

.start-again {
    text-align: center;
    color: white;
}

@media only screen and (max-width: 600px) {
    .wrong-answer {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .wrong-answer-heading {
        font-size: 40px;
    }
}