footer {
    position: fixed;
    bottom: 0;
    background-color: #f7931a;
    display: block;
    width: 100%;
}

footer nav {
    display: flex;
    justify-content: center;
}

footer nav ul {
    display: flex;
    list-style: none;
}

footer nav ul a {
    color: white;
    text-decoration: none;
}