@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Caveat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
}

.input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  outline: none;
}

.no-resize {
  resize: none;
}

.btn {
  padding: 12px 25px;
  background: #f7931a;
  border: 0;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.btn:disabled {
  background: #f8c68a;
}

.custom-table {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  border-collapse: collapse;
}

.custom-table th {
  padding: 0;
}

.custom-table tbody tr {
  margin: 3px 0;
}

.custom-table tbody tr:hover td{
  background: darkgray;
  color: white;
}

.custom-table td {
  padding: 8px 0;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.cursor-pointer {
  cursor: pointer;
}

.error {
  border: 1px solid red;
}

.bold {
  font-weight: bold;
}