.answers {
    max-width: 1000px;
    margin: 5px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.answers.disabled > article {
    background-color: gray;
}

@media only screen and (max-width: 600px) {
    .answers {
        flex-direction: column;
    }
}