.report-error{
    border: 1px solid black;
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.report-error-heading {
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.report-error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    .report-error {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .report-error-heading {
        font-size: 40px;
    }
}