@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Caveat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
}

.input {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  outline: none;
}

.no-resize {
  resize: none;
}

.btn {
  padding: 12px 25px;
  background: #f7931a;
  border: 0;
  border-radius: 8px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.btn:disabled {
  background: #f8c68a;
}

.custom-table {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  border-collapse: collapse;
}

.custom-table th {
  padding: 0;
}

.custom-table tbody tr {
  margin: 3px 0;
}

.custom-table tbody tr:hover td{
  background: darkgray;
  color: white;
}

.custom-table td {
  padding: 8px 0;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.cursor-pointer {
  cursor: pointer;
}

.error {
  border: 1px solid red;
}

.bold {
  font-weight: bold;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pointer {
  cursor: pointer;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
    background-color: #f7931a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
    height: 60px;
}

header h1 {
    margin: 0;
    font-size: 42px;
    text-transform: uppercase;
}

header nav {
    display: flex;
    align-items: center;
}

header nav ul {
    display: flex;
    list-style: none;
}

header nav li {
    margin-right: 10px;
}

header nav li a {
    font-size: 18px;
    color: white;
    text-decoration: none;
}

header h1 > a {
    text-decoration: none;
    color: black;
}

.stats {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 100px;
    background-color: lightblue;
    padding: 10px 80px 10px 70px;
    border-bottom-left-radius: 25px;
}

.stats::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 15px;
    width: 100%;
}

.stats-bar {
    z-index: 2;
    content: '';
    background: rgb(73, 175, 64);
    background: linear-gradient(261deg, rgba(73, 175, 64, 1) 0%, rgba(186, 228, 135, 0.6404936974789917) 100%);
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
}

.no-xp::after {
    content: none;
}

@media only screen and (max-width: 600px) {
    header {
        padding: 10px 0;
        flex-direction: column;
        height: unset;
    }

    header nav ul {
        flex-direction: column;
    }

    header ul {
        padding: 0;
    }

    .stats {
        position: static;
        display: block;
        border-bottom-right-radius: 25px;
        text-align: center;
        background-color: unset;
    }

    .stats::after {
        content: none;
    }

    .stats-bar {
        display: none;
    }
}

h1, p {
    text-align: center;
}

main {
    position: relative;
}

main h1 {
    margin-top: 10px;
}

.quiz-themes {
    display: flex;
    max-width: 1500px;
    margin: 50px auto;
    flex-wrap: wrap;
}

.home-image {
    display: flex;
    margin: 0 auto;
    padding: 40px 0 50px 0;
    opacity: .5;
}

.flex-btn {
    display: flex;
    justify-content: center;
}

.flex-btn button:first-of-type {
    margin-right: 10px;
}

.flex-btn button:nth-child(2) {
    margin-left: 10px;
}

.game-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0;
}

.game-details h3 {
    font-size: 28px;
}

@media only screen and (max-width: 600px) {
    .quiz-themes {
        flex-direction: column;
    }

    .quiz-theme {
        margin-left: 10px;
    }
}

.quiz-theme {
    margin-top: 40px;
    margin-right: 10px;
    flex-basis: 24.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    background-color: rgba(255, 255, 0, 0.297);
    cursor: pointer;
    position: relative;
}

.quiz-theme > img {
    margin-bottom: 20px;
    width: 128px;
    position: relative;
    z-index: 2;
}

.quiz-theme p {
    text-align: center;
    padding: 8px 0;
    margin: 8px 0;
    font-size: 26px;
    background-color: #6b400c;
    color: white;
    width: 100%;
    position: relative;
    z-index: 2;
}

img.lock-image{
    width: 210px;
    height: 210px;
    opacity: 1;
    position: absolute;
    z-index: 1;
}
.lds-roller {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: black;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@-webkit-keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.login-page input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
}

input.error {
    border: 1px solid red;
}
.register-page input {
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
}

input.error {
    border: 1px solid red;
}
.answers {
    max-width: 1000px;
    margin: 5px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.answers.disabled > article {
    background-color: gray;
}

@media only screen and (max-width: 600px) {
    .answers {
        flex-direction: column;
    }
}
.question {
    flex-basis: 40%;
    padding: 20px;
    margin: 20px;
    border-radius: 12px;
    background-color: #f7931a;
}


.timer-end {
    border: 1px solid black;
    background-color: rgba(95, 158, 160, .8);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.timer-end-heading {
    color: white;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.timer-end-text {
    color: white;
}

.timer-end-refresh-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .timer-end {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .timer-end-heading {
        font-size: 40px;
    }
}
.wrong-answer {
    border: 1px solid black;
    background-color: rgba(219, 69, 69, .8);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.wrong-answer-heading {
    color: white;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.wrong-answer-text {
    color: white;
}

.wrong-answer-img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.wrong-answer-report-error {
    font-weight: bold;
    text-decoration: underline;
}

.start-again {
    text-align: center;
    color: white;
}

@media only screen and (max-width: 600px) {
    .wrong-answer {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .wrong-answer-heading {
        font-size: 40px;
    }
}
.completed {
    border: 1px solid black;
    background-color: rgb(88, 205, 108);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.completed-heading {
    color: white;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.completed-text {
    color: white;
}

.completed-image-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .completed {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .completed-heading {
        font-size: 40px;
    }
}
.report-error{
    border: 1px solid black;
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.report-error-heading {
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.report-error-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    .report-error {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .report-error-heading {
        font-size: 40px;
    }
}
.admin-panel-nav {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.admin-panel-nav ul {
    display: flex;
    list-style: none;
}

.admin-panel-nav li {
    margin-right: 10px;
}

.admin-panel-nav a {
    color: white;
    background-color: #f7931a;
    padding: 12px 20px;
    border-radius: 8px;
    text-decoration: none;
}

a.active {
    background-color: #643e10;
}

.main-admin-wrapper {
    /*border: 1px solid black;*/
    max-width: 70%;
    margin: 30px auto;
}

.admin-panel-heading-a {
    color: inherit;
    text-decoration: none;
}

.please-select-page {
    text-align: center;
    font-size: 32px;
    margin: 2px;
}
.admin-panel-users-green {
    color: green;
}

.admin-panel-users-red {
    color: red;
}
.admin-panel-questions-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.admin-panel-questions {
    margin-bottom: 50px;
}
.admin-panel-categories-actions {
    display: flex;
    justify-content: center;
}
.delete-category {
    border: 1px solid black;
    background-color: rgb(160, 15, 33);
    position: fixed;
    width: 40%;
    height: 30vh;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.delete-category-heading {
    color: white;
    text-transform: uppercase;
    font-size: 80px;
    margin: 0;
}

.delete-category-text {
    color: white;
}

.delete-category-image-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    height: 100px;
}

.delete-category-image-wrapper > img {
    height: 100%;
}

.delete-category-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.delete-category-button-wrapper button {
    padding: 12px 20px;
}

.delete-category-button-wrapper button:nth-child(1) {
    position: relative;
    right: 20px;
    background-color: red;
}

.delete-category-button-wrapper button:nth-child(2) {
    position: relative;
    left: 20px;
    background-color: green;
}

@media only screen and (max-width: 600px) {
    .delete-category {
        width: 50%;
        height: 60vh;
        top: 45%;
        left: 50%;
    }

    .delete-category-heading {
        font-size: 40px;
    }
}
.admin-panel-categories-create-heading {
    text-align: center;
}
.admin-panel-questions-create-question-heading {
    text-align: center;
}

form .input-inline {
    padding-left: 8%;
    width: 92%;
    display: inline-block;
}

.remove-input {
    position: relative;
    top: -33.5px;
}

.admin-panel-question-flex {
    display: flex;
    align-content: center;
    margin: 20px 0;
}

.admin-panel-question-flex > * {
    margin:0 8px 0 0;
}

.admin-panel-home-page  {
    text-align: left;
}

span.bold {
    font-weight: bold;
}

span.space {
    margin-right: 5px;
}
.report-error-table-tr {
    cursor: pointer;
}
.report-details p {
    text-align: left;
}

.report-details .delete-btn {
    margin-left: 50px;
}
.stats-heading {
    text-align: center;
}

.stats-user {
    background-color: #f7931a;
    color: white;
}

.stats-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.stats-buttons > button {
    margin-right: 20px;
}
footer {
    position: fixed;
    bottom: 0;
    background-color: #f7931a;
    display: block;
    width: 100%;
}

footer nav {
    display: flex;
    justify-content: center;
}

footer nav ul {
    display: flex;
    list-style: none;
}

footer nav ul a {
    color: white;
    text-decoration: none;
}

.profile-heading {
    text-align: center;
}

.user-profile p{
    text-align: left;
}
button.center-btn {
    margin: 0 auto;
    display: block;
}

#not-found-page button {
    cursor: pointer;
}
