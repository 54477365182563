.stats-heading {
    text-align: center;
}

.stats-user {
    background-color: #f7931a;
    color: white;
}

.stats-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.stats-buttons > button {
    margin-right: 20px;
}