header {
    background-color: #f7931a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
    height: 60px;
}

header h1 {
    margin: 0;
    font-size: 42px;
    text-transform: uppercase;
}

header nav {
    display: flex;
    align-items: center;
}

header nav ul {
    display: flex;
    list-style: none;
}

header nav li {
    margin-right: 10px;
}

header nav li a {
    font-size: 18px;
    color: white;
    text-decoration: none;
}

header h1 > a {
    text-decoration: none;
    color: black;
}

.stats {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 100px;
    background-color: lightblue;
    padding: 10px 80px 10px 70px;
    border-bottom-left-radius: 25px;
}

.stats::after {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    height: 15px;
    width: 100%;
}

.stats-bar {
    z-index: 2;
    content: '';
    background: rgb(73, 175, 64);
    background: linear-gradient(261deg, rgba(73, 175, 64, 1) 0%, rgba(186, 228, 135, 0.6404936974789917) 100%);
    position: absolute;
    top: 0;
    right: 0;
    height: 15px;
}

.no-xp::after {
    content: none;
}

@media only screen and (max-width: 600px) {
    header {
        padding: 10px 0;
        flex-direction: column;
        height: unset;
    }

    header nav ul {
        flex-direction: column;
    }

    header ul {
        padding: 0;
    }

    .stats {
        position: static;
        display: block;
        border-bottom-right-radius: 25px;
        text-align: center;
        background-color: unset;
    }

    .stats::after {
        content: none;
    }

    .stats-bar {
        display: none;
    }
}
