h1, p {
    text-align: center;
}

main {
    position: relative;
}

main h1 {
    margin-top: 10px;
}

.quiz-themes {
    display: flex;
    max-width: 1500px;
    margin: 50px auto;
    flex-wrap: wrap;
}

.home-image {
    display: flex;
    margin: 0 auto;
    padding: 40px 0 50px 0;
    opacity: .5;
}

.flex-btn {
    display: flex;
    justify-content: center;
}

.flex-btn button:first-of-type {
    margin-right: 10px;
}

.flex-btn button:nth-child(2) {
    margin-left: 10px;
}

.game-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0;
}

.game-details h3 {
    font-size: 28px;
}

@media only screen and (max-width: 600px) {
    .quiz-themes {
        flex-direction: column;
    }

    .quiz-theme {
        margin-left: 10px;
    }
}
